import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

import { navigate } from 'gatsby';

import { requestPasswordReset } from '../lib/api';
import { userLoggedIn } from '../redux/actions';

import { Base, Jumbo } from '../components/common';
import SEO from '../components/seo';

import './login.css';
import InfoModal from '../components/common/modals/InfoModal';

const BUTTON_STYLE = {
  backgroundColor: 'var(--color-lylli-purple-dark)',
  borderColor: 'var(--color-lylli-purple-dark)',
  borderRadius: '32px',
  fontFamily: 'var(--font-headers)',
  marginBottom: '1rem',
  padding: '0.75rem 1.25rem',
};

class LoginPage extends React.Component {
  state = {
    completed: false,
    email: '',
    error: null,
    message: '',
    show: false,
  };

  handleSubmit = async (e) => {
    const { email } = this.state;

    e.preventDefault();
    e.stopPropagation();

    let completed = false;
    let message;
    try {
      await requestPasswordReset(email);
      message = 'En återställningslänk har skickats till din e-post.';
      completed = true;
    } catch (ex) {
      message = ex.message;
    }

    this.setState({
      completed,
      message,
      show: true,
    })

    return false;
  };

  handleEmailChanged = ({ target: { value } }) => {
    this.setState({ email: value });
  };

  handleClose = async () => {
    const { completed } = this.state;
    this.setState({ show: false });
    if (completed) {
      await navigate('/');
    }
  };

  render() {
    const { email, message, show } = this.state;

    return (
      <Base hideFooter>
        <SEO title="Glömt lösenord" />
        <Jumbo className="login">
          <InfoModal show={show} onClose={this.handleClose}>{message}</InfoModal>
          <Row>
            <Col lg={4} />
            <Col
              lg={4}
              style={{
                padding: '1rem',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                border: 'white 1px solid',
              }}
            >
              <h2>Glömt lösenord</h2>
              <p>För att återställa ditt lösenord, fyll i din e-post.</p>

              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="E-post"
                    value={email}
                    onChange={this.handleEmailChanged}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" style={BUTTON_STYLE} block>
                  Återställ lösenord
                </Button>

              </Form>
            </Col>
            <Col lg={4} />
          </Row>
        </Jumbo>
      </Base>
    );
  }
}

export default connect(({ user }) => ({ user }), { actionUserLoggedIn: userLoggedIn })(LoginPage);
